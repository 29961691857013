<template>
  <div class="productList">
    <van-tabs v-model:active="active" @change="listChange">
       <!--全部产品-->
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="listComponent.loading"
              :finished="listComponent.finished"
              :error="listComponent.error"
              finished-text="没有更多了"
              error-text="请求失败，点击重新加载"
              @load="loadMore"
            >
        <van-tab v-for="(item,index) in tabNav" :title="item.name" :key="index" :val="item.value">
        <div v-for="item in ListData">
          <div >
            <div class="product_cell"  @click="goDetail(item.productId, item.isNovice)">
              <div class="product_list">
                <ul>
                  <li>
                    <div class="product_title">
                      <i></i>{{ item.productName }}
                    </div>
                    <div class="compare divC">
                      <p>
                        <b>
                          <template v-if="item.minStairRate != null">
                            {{
                              ((parseFloat(item.profitRate * 100) + parseFloat(item.minStairRate * 100)) / 100) | numFilter
                            }}%
                          </template>
                          <template v-else> {{ item.profitRate | numFilter }}%</template>

                          <template v-if="item.stairRate != null">
                            ~{{ ((item.profitRate * 100 + item.stairRate * 100) / 100) | numFilter }}%
                          </template>
                          <template v-if="item.floatRate != 0">
                            <span class="floatRate">+{{ item.floatRate }}%</span>
                          </template>
                        </b>
                      </p>
                      <p class="tips">产品业绩比较基准</p>
                    </div>
                    <div class="money divC">
                      <p>
                        起投金额<b>{{ item.productMinimumSubscribe | formatMoney }}</b>
                      </p>
                      <p>
                        产品期限 <b>约{{ item.productPeriod }}天</b>
                      </p>
                    </div>
                    <div class="listBottom">
                      <span>可预约金额{{ item.surplusMatchedAmount | formatMoney }}</span>
                      <span class="listBtn">预约</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        </van-tab>
        <!-- <div class="hotline">
          <div>
            <p>服务热线：<span>4001787888</span></p>
            <p>服务时间：<span>9:00-17:00(工作日)</span></p>
          </div>
        </div> -->
        </van-list>
      </van-pull-refresh>
      <div class="noContent" v-if="ListFlag">
        <!-- <img src="/images/noContent.png"/> -->
        <p>暂无记录</p>
      </div>
    <!--全部产品end-->
  </van-tabs>
   
  </div>
</template>
<script>
import { selectProductList} from "../services/proApi";
import { encryptRSA, decryptRSA, encryptAES, decryptAES } from "../utils/encryption";
import { queryUserIsRealName} from "../services/api";
import { Dialog } from 'vant';
export default {
  data() {
    return {
      active:0,
      tabNav: [{name: '全部'},{name: '定融产品'},{name: '债券类产品'}],
      listComponent: {
        loading: true,
        finished: true,
        error: false,
        page: 1
      },
      refreshing:true,
      active:0,
      ListData: [],
      page: 1,
      ListFlag: false,
      profitRate: "",
      stairRate: "",
      floatRate: "",
      assetsType:-1,
    };
  },
  
  methods: {
    async resetfilter() {
      this.onRefresh();
    },
    async onRefresh() {
      this.ListData = [];
      this.listComponent.page = 1;
      await this.getData();
    },
   loadMore() {
      this.listComponent.page += 1;
      this.getData();
    },
    async getData() {//列表接口
      this.listComponent.loading = true;
      const rows = 10;
      const datas = await selectProductList({
        ...this.filterSearch,
        page: this.listComponent.page,
        pageSize: rows,
        assetsType:this.assetsType,
      });
      this.listComponent.loading = false;
      this.refreshing = false;
      if (datas.code === 1) {
        const list = datas?.data.records;
        if (list?.length) {
          this.ListData = this.ListData.concat(list);
        }
        this.listComponent.finished = !list || (this.ListData?.length == datas?.data.pages);
        if(this.listComponent.page==datas.data.pages){
          this.listComponent.finished =true
        }
      }
    },
    goDetail(productId){//去预约页面
       this.$router.push({
        path: '/productDetail',
        query: {
          productId:productId,
        }
      });
   },
    async queryUserIsRealName() {//校验实名接口
      const _this = this;
      const datas = await queryUserIsRealName({ });
          let encrypted = datas.data.encrypted;
          let data = datas.data.msg;
          let aseKey = decryptRSA(encrypted); //RSA解密
          let res = JSON.parse(decryptAES(data, aseKey)); //AES解密
            if (datas.res_code == 1) {
              let encrypted = datas.data.encrypted;
              let data = datas.data.msg;
              let aseKey = decryptRSA(encrypted); //RSA解密
              let res = JSON.parse(decryptAES(data, aseKey)); //AES解密
              console.log(res)
              //0未实名 1 已实名未实名绑卡、3去上传证件 、4提示审核中弹框
              if (res.status == 0) {
                Dialog.alert({
                  title: '温馨提示',
                  confirmButtonText:'去实名',
                  message:'您还未实名，购买产品，需要先进行实名操作',
                })
                .then(() => {
                  // on confirm
                    _this.$router.push({
                    path: '/realName',
                    query: {}
                  });
                })
              } else if (res.status == 3) {
                 _this.$router.push({
                    path: '/upLoadCertificate',
                    query: {
                      reserveId:_this.reserveId,
                    }
                  });
              } else if (res.status == 4) {
                Dialog.alert({
                  title: '温馨提示',
                  confirmButtonText:'知道了',
                  message:'实名认证审核中，如需加急处理，请联系客服：4001787888',
                })
                .then(() => {
                  // on confirm
                })
              }
            }
    },
     listChange(val){//获取tab切换参数
      switch(val){
        case 0:
        var  assetsType = -1
        var typeSignStr = '全部'
          break;
        case 1:
        var  assetsType = 0
        var typeSignStr = '定融产品'
          break;
        case 2:
        var  assetsType = 1
        var typeSignStr = '债券类产品'
          break;
      }
      this.assetsType=assetsType;
      this.ListData=[];
      this.listComponent.page=1;
      this.getData();
    },
  },
  created: function() {
    var _this = this;
    let  dialogBtn,msg,path;
    _this.onRefresh('','WAP','');
    _this.queryUserIsRealName();
  },
  filters: {
    formatMoney: function(money) {
      money = money > 9999 ? money / 10000 + "万" : money;
      return money;
    },
    numFilter: function(value) {
      let realVal = "";
      if (!isNaN(value) && value !== "") {
        // 截取当前数据到小数点后两位
        realVal = parseFloat(value).toFixed(2);
      } else {
        realVal = "--";
      }
      return realVal;
    }
  },
};
</script>
<style scoped lang="scss">
.productList{padding-bottom:6rem;}
.productListBanner img {
    width: 100%;
}
.product_list{
    font-size: 0.8rem;
    li{
        background: url(../assets/img/list-bg.png) no-repeat;
        background-size: cover;
        margin: 1rem 0.5rem;
        padding: 0.5rem 0;
        position: relative;
        .product_title {
            font-size: 1.1rem;
            padding-bottom: 1.5rem;
            padding-top:0.5rem;
            font-weight: bold;
            i{border-left: 0.2rem solid #0398FF;padding-right: 0.3rem;}
        }  
        .compare{
            text-align: left;padding-left: 0.5rem;
            p:first-child{
                b{color: #FF0000;}
            }
            p{
                b{font-size: 1.1rem;font-weight: bold;position: relative;}
                .floatRate{
                    font-size: .6rem;
                    color: #FF0000;
                    background: url(../assets/img/bubble_red.png) no-repeat;
                    width: 2.8rem;
                    height: 1.6rem;
                    line-height:1rem;
                    background-size: contain;
                    position: absolute;
                    text-align: center;
                    margin-top: -11px;
                    margin-left: 0.5rem;
                }
             }
             .tips{font-size: 0.8rem;
                color: #999;
                padding-top: 1rem;}
        } 
        .money{
            color:#999;font-size: 0.8rem;
            p:last-child{padding-top:1rem;}
            b{font-size:1rem;color: #333;padding-left: 0.5rem}
        }
        .divC{
            display: inline-block;
            width: 46%;
            text-align: left;
            padding-bottom: 1rem
        }
        .listBottom{
            border-top: 1px solid #ddd;
            padding: 0.5rem;
            line-height: 1.5rem;
        }
        .listBtn{
            background: -webkit-linear-gradient(left,#0398FF,#356BFF);
            border-radius:2.5rem;
            color: #F4F4F4;
            float: right;
            width:4.2rem;
            height: 1.9rem;
            text-align: center;
            line-height: 1.9rem;
            padding: 0;
            font-size: 0.8rem;
        }
    }
}
.hotline{
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 1rem;
    div{
        display: inline-block;
        text-align: left;
        margin-left: 0.5rem;
        p{line-height: 1.5rem;}
    }
}
 .noContent{
      text-align: center;
      img{width: 50%;padding-top:6rem;}
      p{    font-size: 1.2rem;
        color: #999;}
    }
</style>
